import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { BACKEND_URL, GOOGLE_USER_ID } from '../constants/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cons from '../constants/config';

export default function Login() {
  // State management using hooks
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [usernameG, setUsernameG] = useState('');
  const [passwordG, setPasswordG] = useState('');

  const clientId = `${GOOGLE_USER_ID}`;
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const usernameHandler = event => {
    setUsername(event.target.value);
  };

  const passwordHandler = event => {
    setPassword(event.target.value);
  };

  // Function to handle form submission
  const submitHandler = event => {
    event.preventDefault();
    loginRequest();
  };

  // Function to make a login request
  async function loginRequest() {
    try {
      await fetch(Cons + '/login', {
        method: 'POST',
        body: JSON.stringify({
          accion: 'login',
          username: username,
          password: password,
        }),
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          if (data.respuesta === 'success') {
            localStorage.setItem('token', data.data.status);
            localStorage.setItem('email', data.data.email);
            localStorage.setItem('username', data.data.userName);
            toast.success(data.mensaje);
            navigate('/home');
          } else {
            toast.warn(data.mensaje);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  // Function to make a login request whit email
  async function loginRequestGmail() {
    try {
      // Making a POST request to the backend for login
      await fetch(`${BACKEND_URL}/login`, {
        method: 'POST',
        body: JSON.stringify({
          accion: 'login',
          username: usernameG,
          password: passwordG,
        }),
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          if (data.respuesta == 'success') {
            localStorage.setItem('token', data.data.status);
            localStorage.setItem('email', data.data.email);
            localStorage.setItem('username', data.data.userName);
            toast.success(data.mensaje);
            navigate('/home');
          } else {
            toast.warn('No user found please register');
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: 'email profile',
      });
    };
    gapi.load('client:auth2', initClient);
  });

  const onSuccess = res => {
    setUsernameG(res.profileObj.email);
    setPasswordG(res.profileObj.googleId);
    if (passwordG.length > 3) {
      setTimeout(() => {
        loginRequestGmail();
      }, 500);
    }
  };

  const onFailure = err => {
    console.log('failed:', err);
  };

  return (
    <form className="login-form">
      <ToastContainer />
      <h2>Login</h2>
      <label>Email</label>
      <input type="email" value={username} onChange={usernameHandler} required />
      <label>Password</label>
      <div class="input-group mb-3">
        <input
          type={passwordShown ? 'text' : 'password'}
          value={password}
          onChange={passwordHandler}
          required
          class="form-control"
        />
        <span class="input-group-text" id="basic-addon2" onClick={togglePasswordVisiblity}>
          {passwordShown ? 'Hide Pass' : 'Show Pass'}
        </span>
      </div>
      <button onClick={submitHandler}>Login</button>
      <Link to="/register">Register</Link>
      <div className="">
        <GoogleLogin
          clientId={clientId}
          buttonText="Login with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          isSignedIn={true}
        />
      </div>
      <Link to="/home">Home</Link>
      <Link to="/forgotPassword">Forgot Password</Link> {/* Agrega el enlace para resetear la contraseña */}
    </form>
  );
}
