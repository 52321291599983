import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Google } from 'react-bootstrap-icons';

function ComingSoon() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="btn btn-primary btn-sm" id="customGCButton" variant="primary" onClick={handleShow}>
        <Google />
        &nbsp; Import contacts from google
      </Button>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="text-center">Coming Soon</h2>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ComingSoon;
