import React from 'react';
export default function KrispLogo() {
  return (
    <svg width="71" height="38" viewBox="0 0 71 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.6192 27.374H36.6706C36.8619 27.374 37.0183 27.2175 37.0183 27.0262V11.5743C37.0183 11.3831 36.8619 11.2266 36.6706 11.2266H32.6192C32.428 11.2266 32.2715 11.3831 32.2715 11.5743V27.0291C32.2744 27.2204 32.428 27.374 32.6192 27.374Z"
        fill="#232930"
      />
      <path
        d="M9.69928 19.1555L17.2745 11.5802C17.405 11.4498 17.3122 11.2238 17.1267 11.2238H11.0063C10.9512 11.2238 10.899 11.2469 10.8585 11.2846L6.91434 15.2287V4.86854C6.91434 4.72074 6.76364 4.61931 6.62744 4.67437L2.29789 6.42764C2.21964 6.45952 2.16748 6.53486 2.16748 6.6218V27.0263C2.16748 27.2176 2.32397 27.3741 2.51524 27.3741H6.56658C6.75785 27.3741 6.91434 27.2176 6.91434 27.0263V23.0822L11.1512 27.3161C11.1888 27.3538 11.2439 27.377 11.299 27.377L17.4223 27.3741C17.6078 27.3741 17.7005 27.1481 17.5701 27.0176L9.69928 19.1555Z"
        fill="#232930"
      />
      <path
        d="M68.8122 18.721C68.534 14.7421 65.3085 11.5167 61.3267 11.2443C56.6089 10.9197 52.685 14.6494 52.685 19.3006H52.6821V33.7325C52.6821 33.8832 52.8328 33.9817 52.969 33.9266L57.2116 32.2081C57.3449 32.156 57.429 32.0285 57.429 31.8865V26.6557C58.4433 27.1164 59.5706 27.3772 60.7587 27.3772C65.4071 27.3743 69.1396 23.4447 68.8122 18.721ZM60.7587 22.6304C58.9185 22.6304 57.429 21.1379 57.429 19.3006C57.429 17.4633 58.9185 15.9709 60.7587 15.9709C62.5961 15.9709 64.0856 17.4633 64.0856 19.3006C64.0856 21.1379 62.5961 22.6304 60.7587 22.6304Z"
        fill="#232930"
      />
      <path
        d="M45.02 15.1156C45.0345 15.1156 45.0519 15.1156 45.0664 15.1185C45.8459 15.0866 46.5473 15.5851 47.1181 16.3646C47.2109 16.4922 47.379 16.5356 47.5239 16.4777L50.787 15.1649C50.9666 15.0924 51.0536 14.8867 50.9782 14.707C50.1581 12.7306 47.663 11.2266 45.0171 11.2266C41.5628 11.2266 38.7575 13.4232 38.7575 16.1328C38.7575 17.5847 39.6037 18.8453 40.7658 19.6683C41.8091 20.4073 43.0552 20.8855 44.2839 21.2042C44.3245 21.2158 44.3651 21.2245 44.4057 21.2361C46.0633 21.6042 46.3009 22.0417 46.3009 22.4735C46.3009 23.0357 45.6199 23.4907 44.7824 23.4907C43.9159 23.5458 43.1479 22.9517 42.5452 22.0475C42.4553 21.9113 42.2786 21.8621 42.1279 21.9229L38.9024 23.2444C38.7257 23.3168 38.6445 23.5139 38.7112 23.6936C39.6356 26.1974 42.1279 27.3798 44.7824 27.3798C48.2368 27.3798 51.042 25.186 51.042 22.4735C51.042 21.8824 50.9087 21.3173 50.6624 20.7927C50.4276 20.2914 49.8161 19.2249 48.2339 18.486C47.3297 18.0657 44.823 17.3268 43.9623 16.8515C43.6667 16.6892 43.4986 16.4197 43.4986 16.1328C43.4986 15.5706 44.1825 15.1156 45.02 15.1156Z"
        fill="#232930"
      />
      <path
        d="M34.6449 9.63846C36.2758 9.63846 37.5979 8.31635 37.5979 6.68544C37.5979 5.05453 36.2758 3.73242 34.6449 3.73242C33.014 3.73242 31.6919 5.05453 31.6919 6.68544C31.6919 8.31635 33.014 9.63846 34.6449 9.63846Z"
        fill="#FF7E5C"
      />
      <path
        d="M30.4052 11.2266C30.553 11.2266 30.6544 11.3773 30.5993 11.5135L28.8229 15.8401C28.791 15.9184 28.7157 15.9676 28.6316 15.9676H27.3507C25.5192 15.9676 24.0355 17.4514 24.0355 19.2829V27.0291C24.0355 27.2204 23.8819 27.374 23.6906 27.374H19.6393C19.448 27.374 19.2915 27.2175 19.2915 27.0262V19.2829C19.2915 14.8345 22.8995 11.2266 27.3478 11.2266H30.4052Z"
        fill="#232930"
      />
    </svg>
  );
}
