import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BACKEND_URL, GOOGLE_USER_ID } from '../constants/config';

/**
 * A functional component to handle user registration and login.
 *
 * @returns {JSX.Element} JSX element containing registration and login forms.
 */
export default function Login() {
  const navigate = useNavigate();
  // State variables to store user registration data
  const [name, setName] = useState('');
  const [lastname, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [ImageUrl, setImageUrl] = useState('');

  // State variables to store Google registration data
  const [nameG, setNameG] = useState('');
  const [lastnameG, setLastNameG] = useState('');
  const [usernameG, setUsernameG] = useState('');
  const [passwordG, setPasswordG] = useState('');
  const [emailG, setEmailG] = useState('');
  const [ImageUrlG, setImageUrlG] = useState('');
  const clientId = `${GOOGLE_USER_ID}`;

  // State variables to manage password visibility and validity
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordsValid, setPasswordsValid] = useState(true);

  // Toggle password visibility
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  // Event handlers for form inputs
  const nameHandler = event => {
    setName(event.target.value);
  };

  const lastnameHandler = event => {
    setLastName(event.target.value);
  };

  const usernameHandler = event => {
    setUsername(event.target.value);
  };

  const passwordHandler = event => {
    setPassword(event.target.value);
    validatePassword(event.target.value);
    if (passwordValid == true) {
      comparePasswords(event.target.value, confirmPassword);
    }
  };

  const validatePassword = password => {
    if (password.length >= 8) {
      setPasswordValid(true);
      setPasswordsValid(true);
      setPasswordsMatch(false);
    } else {
      setPasswordsValid(false);
      setPasswordValid(false);
      setPasswordsMatch(true);
    }
  };
  const confirmPasswordHandler = event => {
    setConfirmPassword(event.target.value);
    comparePasswords(password, event.target.value);
  };
  const emailHandler = event => {
    setEmail(event.target.value);
  };

  // Handle user registration request
  async function registerRequest() {
    try {
      await fetch(`${BACKEND_URL}/register`, {
        method: 'POST',
        body: JSON.stringify({
          accion: 'register',
          name: name,
          lastname: lastname,
          username: username,
          password: password,
          email: email,
        }),
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          if (data.respuesta == 'success') {
            localStorage.setItem('token', data.data);
            navigate('/confirm');
          } else if (data.respuesta == 'email') {
            toast.warn(data.mensaje);
          } else {
            toast.warn(data.mensaje);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  // Handle Google registration request gmail
  async function registerRequestGmail() {
    try {
      await fetch(`${BACKEND_URL}/register`, {
        method: 'POST',
        body: JSON.stringify({
          accion: 'register',
          name: nameG,
          lastname: lastnameG,
          username: usernameG,
          password: passwordG,
          email: emailG,
        }),
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          if (data.respuesta == 'success') {
            navigate('/confirm');
            toast.success(data.mensaje);
          } else if (data.respuesta == 'email') {
            toast.warn(data.mensaje);
          } else {
            toast.warn(data.mensaje);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  // Handle form submission for regular registration
  const submitHandler = event => {
    event.preventDefault();
    registerRequest();
  };

  // Load Google API client on component mount
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: 'email profile',
      });
    };
    gapi.load('client:auth2', initClient);
  });

  // Handle Google API login success
  const onSuccess = res => {
    setNameG(res.profileObj.givenName);
    setLastNameG(res.profileObj.familyName);
    setUsernameG(res.profileObj.name);
    setPasswordG(res.profileObj.googleId);
    setEmailG(res.profileObj.email);
    setImageUrlG(res.profileObj.imageUrl);
    if (passwordG.length) {
      setTimeout(() => {
        registerRequestGmail();
      }, 500);
    }
  };

  // Handle Google API login failure
  const onFailure = err => {
    console.log('failed:', err);
  };

  // Compare entered passwords
  const comparePasswords = (password, confirmPassword) => {
    if (password === confirmPassword) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  // JSX rendering of registration form
  return (
    <form className="register-form" onSubmit={submitHandler}>
      <ToastContainer />
      <h2>Register</h2>
      <label>Name</label>
      <input type="text" value={name} onChange={nameHandler} required />
      <label>Last Name</label>
      <input type="text" value={lastname} onChange={lastnameHandler} required />
      <label>Username</label>
      <input type="text" value={username} onChange={usernameHandler} required />
      <label>Password</label>
      <input type={passwordShown ? 'text' : 'password'} value={password} onChange={passwordHandler} required />
      <span className="badge text-bg-secondary" onClick={togglePasswordVisiblity}>
        {passwordShown ? 'Hide Pass' : 'Show Pass'}
      </span>
      {!passwordsValid && (
        <div class="alert alert-danger alert-sm" role="alert">
          please add a password with 8 characters
        </div>
      )}
      <label>Confirm Password</label>
      <input
        type={passwordShown ? 'text' : 'password'}
        value={confirmPassword}
        onChange={confirmPasswordHandler}
        required
      />
      {!passwordsMatch && (
        <div class="alert alert-danger" role="alert">
          Passwords do not match
        </div>
      )}
      <label>Email</label>
      <input type="email" value={email} onChange={emailHandler} required />
      {passwordsMatch && <button>Register</button>}

      <Link to="/login">Login</Link>
      <div className="panel">
        <GoogleLogin
          clientId={clientId}
          buttonText="Register with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          isSignedIn={true}
        />
      </div>
    </form>
  );
}
