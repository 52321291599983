import React from 'react';

export default function LinkIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="#707578"
        fillRule="evenodd"
        d="M 19.333 14.667 a 4.66 4.66 0 0 0 -3.839 2.024 L 8.985 13.752 a 4.574 4.574 0 0 0 0.005 -3.488 l 6.5 -2.954 a 4.66 4.66 0 1 0 -0.827 -2.643 a 4.633 4.633 0 0 0 0.08 0.786 L 7.833 8.593 a 4.668 4.668 0 1 0 -0.015 6.827 l 6.928 3.128 a 4.736 4.736 0 0 0 -0.079 0.785 a 4.667 4.667 0 1 0 4.666 -4.666 Z M 19.333 2 a 2.667 2.667 0 1 1 -2.666 2.667 A 2.669 2.669 0 0 1 19.333 2 Z M 4.667 14.667 A 2.667 2.667 0 1 1 7.333 12 A 2.67 2.67 0 0 1 4.667 14.667 Z M 19.333 22 A 2.667 2.667 0 1 1 22 19.333 A 2.669 2.669 0 0 1 19.333 22 Z"
      />
    </svg>
  );
}
