import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BACKEND_URL } from '../constants/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/*
|--------------------------------------------------------------------------
| AccountConfirm Component
|--------------------------------------------------------------------------
|
| This component represents the account registration confirmation page.
| It allows the user to enter the confirmation code received by email to complete the registration process.
| Upon successful confirmation, the user is redirected to the home page.
|
| Dependencies:
|   - react
|   - react-router-dom
|   - react-toastify
|   - 'react-toastify/dist/ReactToastify.css'
|
| Props:
|   None
|
*/

export default function AccountConfirm() {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleTermsAcceptance = () => {
    setTermsAccepted(!termsAccepted);
  };

  // Function to handle changes in the confirmation code input
  const codeHandler = event => {
    setCode(event.target.value);
  };

  // Function to send the confirmation code to the backend for verification
  async function confirmRequest() {
    try {
      await fetch(`${BACKEND_URL}/confirm`, {
        method: 'POST',
        body: JSON.stringify({
          accion: 'confirm',
          code: code,
        }),
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          if (data.respuesta === 'success') {
            localStorage.setItem('token', data.data.status);
            localStorage.setItem('email', data.data.email);
            localStorage.setItem('username', data.data.userName);
            navigate('/home'); // Redirect to the home page on successful confirmation
          } else if (data.respuesta === 'email') {
            toast.success(data.mensaje); // Show success message on email already confirmed
          } else {
            toast.warn(data.mensaje); // Show warning message on invalid confirmation code
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  // Function to handle form submission
  const submitHandler = event => {
    event.preventDefault();

    if (termsAccepted) {
      confirmRequest(); // Call the confirmRequest function to verify the code
    } else {
      toast.warn('Please accept the terms and conditions.');
    }
  };

  return (
    <div className="container">
      <form className="mt-2" onSubmit={submitHandler}>
        <div className="mb-4">
          <div className="card">
            <div className="card-header" id="termsAccordionHeader">
              <h2 className="mb-0">
                <button
                  className="btn btn-link"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#termsAccordion"
                  aria-expanded="true"
                  aria-controls="termsAccordion"
                >
                  TERMS OF USE
                </button>
              </h2>
            </div>
            <div
              id="termsAccordion"
              className="accordion-collapse collapse show"
              aria-labelledby="termsAccordionHeader"
            >
              <div className="card-body">
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        Privacy Policy
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
                      <div class="accordion-body">
                      Last Updated: August 25, 2023
                      <br></br>
                      <strong>Welcome to UjatCare.tel!</strong> This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your personal information when you use our website and services. By using UjatCare.tel, you agree to the practices outlined in this Privacy Policy.

                      Information We Collect

                      Personal Information: When you sign up for UjatCare.tel or use our services, we may collect personal information such as your name, email address, and phone number. This information is used to create and manage your account, provide support, and send you important notifications.

                      Contact Information: With your explicit consent, we may access your device's contact list to enable you to easily connect with your contacts for video conferences and webinars. We collect phone numbers and email addresses from your contacts solely for this purpose and do not share or sell this information to third parties.

                      Usage Data: We may collect information about how you use UjatCare.tel, including the frequency and duration of your interactions, the features you use, and any content you upload or share. This data helps us improve our services and tailor them to your needs.

                      How We Use Your Information

                      Service Provision: We use your personal information to provide you with the requested video conference and webinar services, allowing you to connect with your contacts easily and effectively.

                      Communication: We may use your contact information to send you important updates, notifications, and account-related communications.

                      Improvement: The usage data we collect helps us understand how our services are being used, allowing us to enhance and optimize the user experience.

                      Data Security

                      We take the security of your personal information seriously. We implement industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, and destruction. However, no data transmission or storage method can be guaranteed to be 100% secure. While we strive to protect your personal information, we cannot guarantee its absolute security.

                      Changes to This Policy

                      We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes to the Privacy Policy will be posted on this page, and the "Last Updated" date will be revised accordingly. It is your responsibility to review this Privacy Policy periodically to stay informed about how we are protecting your information.

                      Contact Us

                      If you have any questions, concerns, or suggestions regarding this Privacy Policy or your personal information, please contact us at privacy@ujatcare.tel.

                      By using UjatCare.tel, you acknowledge that you have read and understood this Privacy Policy and agree to its terms. If you do not agree with our practices, please do not use our services. Your continued use of UjatCare.tel after any changes to this Privacy Policy will signify your acceptance of those changes.

                      Thank you for trusting UjatCare.tel with your video conferencing and webinar needs.

                      (Note: This is a generic example of a privacy policy and may need to be customized to align with the specific requirements of your website and applicable laws.)
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        Terms and Conditions
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
                      <div class="accordion-body">
                        T1. Acceptance of Terms

                        Welcome to UJATCARE.TEL! By accessing and using our website, you agree to comply with and be bound by the following terms and conditions. If you do not agree with these terms, please refrain from using our services.

                        2. Services Description

                        UJATCARE.TEL provides easy-to-use, on-demand video conference and webinar services ("Services") free of charge. These services enable users to engage in virtual communication and collaboration. By using our Services, you acknowledge and agree to abide by the guidelines set forth in these terms and conditions.

                        3. Collection of Contact Information

                        As part of our Services, we may collect and store your phone number and email address from your contacts with your explicit consent. This information is used solely for the purpose of facilitating communication and enhancing your user experience. We are committed to protecting your privacy and will not sell, share, or disclose your contact information to third parties without your consent.

                        4. Changes to Terms and Conditions

                        UJATCARE.TEL reserves the right to modify, update, or change these terms and conditions at any time without prior notice. It is your responsibility to review these terms periodically for any updates. Continued use of our Services after any modifications constitutes your acceptance of the revised terms.

                        5. User Responsibilities

                        When using UJATCARE.TEL's Services, you agree to:

                        Provide accurate and up-to-date information during the registration process.
                        Use the Services in compliance with all applicable laws and regulations.
                        Refrain from engaging in any activities that could disrupt or interfere with the functioning of the Services.
                        Respect the privacy and intellectual property rights of other users.
                        6. User Conduct

                        You understand and agree that you are solely responsible for your actions while using our Services. You agree not to:

                        Use the Services for any illegal, harmful, or unauthorized purposes.
                        Engage in any form of harassment, abuse, or discriminatory behavior.
                        Transmit any viruses, malware, or harmful code.
                        Violate the rights of any third party, including intellectual property rights.
                        Attempt to gain unauthorized access to the Services or other users' accounts.
                        7. Limitation of Liability

                        UJATCARE.TEL strives to provide reliable and high-quality Services, but we do not guarantee the absence of technical glitches, interruptions, or errors. By using our Services, you agree that UJATCARE.TEL shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising from the use or inability to use our Services.

                        8. Termination

                        UJATCARE.TEL reserves the right to suspend, terminate, or restrict your access to the Services if you violate these terms and conditions or engage in any unauthorized or harmful activities. Termination may occur without prior notice.

                        9. Governing Law

                        These terms and conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.

                        10. Contact Us

                        If you have any questions or concerns about these terms and conditions, please contact us at [Contact Email Address].

                        By using UJATCARE.TEL's Services, you acknowledge that you have read, understood, and agreed to these terms and conditions.
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="mb-4 d-flex justify-content-end">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  checked={termsAccepted}
                  onChange={handleTermsAcceptance}
                />
                <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                  I ACCEPT THE TERMS AND CONDITIONS
                </label>
              </div>
            </div>
          </div>
        </div>
        {termsAccepted && (
          <div className="card mb-4">
            <div className="card-body">
              <ToastContainer />
              <h2>Account Registration Confirmation</h2>
              <div className="mb-3">
                <label htmlFor="codeInput" className="form-label">
                  The confirmation code has been sent by email
                </label>
                <input type="text" className="form-control" id="codeInput" value={code} onChange={codeHandler} />
              </div>
              <button  className="btn btn-primary">
                Confirm
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
