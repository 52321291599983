import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import '../../../view/logo-ujat-care.png';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginBottom: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  inputField: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  homeButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0',
      marginTop: '7px',
    },
    marginLeft: '7px',
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomDescription: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomDescription: (roomDescription: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({
  name,
  roomDescription,
  roomName,
  setName,
  setRoomDescription,
  setRoomName,
  handleSubmit,
}: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const handleRoomDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomDescription(event.target.value);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Join a Room
      </Typography>
      <Typography variant="body1"></Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div>
              <InputLabel shrink htmlFor="input-user-name">
                Your Name
              </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                onChange={handleNameChange}
              />
            </div>
          )}
          {hasUsername && (
            <>
              <p>Se muestra si está logueado el usuario</p>
            </>
          )}
          {!roomName && ( // Condición para mostrar u ocultar la sección en función de roomName
            <div>
              <InputLabel shrink htmlFor="input-room-name">
                Room Name
              </InputLabel>
              <TextField
                autoCapitalize="false"
                id="input-room-name"
                variant="outlined"
                fullWidth
                size="small"
                value={roomName}
                onChange={handleRoomNameChange}
              />
            </div>
          )}
          {roomDescription != 'UjatCares' && (
            <div>
              <InputLabel shrink htmlFor="input-room-description">
                Room Description
              </InputLabel>
              <TextField
                id="input-room-description"
                variant="outlined"
                fullWidth
                size="small"
                value={roomDescription}
                onChange={handleRoomDescriptionChange}
              />
            </div>
          )}
        </div>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!name || !roomName || !roomDescription}
            className={classes.continueButton}
          >
            Continue
          </Button>
          <Button variant="contained" className={classes.homeButton} href="/#/home">
            Home
          </Button>
        </Grid>
        <div>
          <img className="img-fluid smaller-image" src="https://ujatcares.com/assets/ujatCare_logo.png" />
        </div>
      </form>
    </>
  );
}
