import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import jwt from 'jwt-decode';
import { BACKEND_URL } from '../constants/config';

/**
 * A functional component to display user profile information.
 *
 * @returns {JSX.Element} JSX element containing user profile information.
 */
export default function Profile() {
  // State variables to hold user data
  const [user, setUser] = useState();
  // Decode user token to get username and email
  const userToken = jwt(localStorage.getItem('token'));
  const username = localStorage.getItem('username');
  const email = localStorage.getItem('email');

  /**
   * A function to fetch user data from the backend.
   */
  const getUser = useCallback(async () => {
    try {
      await fetch(`${BACKEND_URL}/user`, {
        method: 'POST',
        body: JSON.stringify({
          accion: 'user',
        }),
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          setUser(data.status);
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  // Fetch user data on component mount
  useEffect(() => {
    getUser();
  }, [getUser]);

  // JSX rendering of user profile information
  return (
    <div className="profile">
      <h2>Profile</h2>
      <label>Username: {username}</label>
      <label>Email: {email}</label>
      <Link to="/">Home</Link>
    </div>
  );
}
