import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { gapi } from 'gapi-script';

export default function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('usernameG');
    localStorage.removeItem('username');
    navigate('/');
  }, [true]);

  return <></>;
}
