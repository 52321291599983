import React, { useEffect, useRef, useState } from 'react';
import Video from 'twilio-video';
import JoinRoomForm from './JoinRoomForm';

function VideoChat() {
  const [roomName, setRoomName] = useState(null);
  const localVidRef = useRef();
  const remoteVidRef = useRef();
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (!roomName || !token) {
      return;
    }

    Video.connect(token, {
      name: roomName,
    }).then(room => {
      // Attach the local video
      Video.createLocalVideoTrack().then(track => {
        localVidRef.current.appendChild(track.attach());
      });

      // Attach video for all remote participants
      room.participants.forEach(participant => {
        participant.tracks.forEach(publication => {
          if (publication.isSubscribed) {
            const track = publication.track;
            remoteVidRef.current.appendChild(track.attach());
          }
        });

        participant.on('trackSubscribed', track => {
          remoteVidRef.current.appendChild(track.attach());
        });
      });
    });
  }, [roomName, token]);

  const handleJoin = async roomName => {
    // Replace this with the URL to your server that generates Twilio access tokens
    const tokenEndpoint = process.env.REACT_APP_TOKEN_ENDPOINT;

    const response = await fetch(tokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ roomName }),
    });

    //const data = await response.json();
    setToken(
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzA0ODFiYTQ3M2E1MmRlZDA5MGI0NzUyOWJhM2UwN2VjLTE2ODk5NzY3NDQiLCJpc3MiOiJTSzA0ODFiYTQ3M2E1MmRlZDA5MGI0NzUyOWJhM2UwN2VjIiwic3ViIjoiQUMyYjUwY2ZkZDgzYjc2MWY1MGRhYTYwMzA0OTA2MzUxYiIsImV4cCI6MTY4OTk4MDM0NCwiZ3JhbnRzIjp7ImlkZW50aXR5IjoiSGVyYmVydCBPdmFsbGUiLCJ2aWRlbyI6eyJyb29tIjoiUm9vbSBuYW1lIn19fQ.Qtw_u7w2Qedk9DHqYOITIQyoXeYKgkhQhzzewUGNc-E'
    );
    setRoomName(roomName);
  };

  return (
    <div>
      <JoinRoomForm onJoin={handleJoin} />
      <div ref={localVidRef} />
      <div ref={remoteVidRef} />
    </div>
  );
}

export default VideoChat;
