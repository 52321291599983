import React, { useCallback, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CopyLink from '../../../icons/CopyLink';

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const lastClickTimeRef = useRef(0);

  const handleShareClick = useCallback(() => {
    const currentURL = window.location.href;
    const index = currentURL.indexOf('/room');
    console.log(index);
    const modifiedURL = currentURL.substring(0, index + 1) + '#/' + currentURL.substring(index + 1) + '/UjatCares';

    navigator.clipboard
      .writeText(modifiedURL)
      .then(() => {
        toast.info('Link copied to clipboard', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })
      .catch(error => {
        console.error('Error copying link to clipboard:', error);
        toast.error('Error copying link to clipboard:', error);
      });
  }, []);

  return (
    <>
      <ToastContainer />
      <Button startIcon={<CopyLink />} onClick={handleShareClick}>
        Share Link
      </Button>
    </>
  );
}
