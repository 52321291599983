import React from 'react';
import { Link } from 'react-router-dom';
import './homePage.css';
import Navbar from './Navbar';

const HomePage = () => {
  return (
    <div className="hp">
      <Navbar />
      <section id="section-1">
        <div className="container">
          <div className="row" id="intro">
            <div className="col-md-6">
              <h1 className="main-intro">On the go Video Communications Services</h1>
              <p>
                No personal data tracking or selling to 3rd. Parties No software to download Free.... yes it is FREE
              </p>
              <Link type="button" class="btn btn-primary" to="/home" className="home-link">
                Get Started
              </Link>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </section>

      <section id="section-2">
        <div id="meetings-info">
          <div className="container">
            <div className="row align-items-centerj py-5">
              <div className="col-md-12 text-center">
                <h2 className="text-center">MEETINGS</h2>
                <p className="text-center">
                  No matter where they are, what language they speak, or how they choose to communicate, create an
                  engaging meeting environment that unites everyone.
                </p>
                <Link type="button" class="btn btn-primary" to="/home" className="home-link">
                  Get Started
                </Link>
              </div>
              <div className="col-md-6 offset-md-3 py-5">
                <img className="img-fluid" src="https://i.ibb.co/Gc8VRwN/vid-conf.jpg" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="section-3">
        <div id="events-info">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <img className="img-fluid" src="https://ujatcare.tel/assets/images/sq4.png" />
              </div>
              <div className="col-md-6 text-center">
                <h2 className="text-center">EVENTS</h2>
                <p className="text-center">
                  Organize physical, virtual, and hybrid events with the help of a robust platform that manages
                  registration, on-site solutions, networking, and everything in between..
                </p>
                <Link type="button" class="btn btn-primary" to="/home" className="home-link">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="section-4">
        <div className="container">
          <div className="row align-items-center py-5">
            <div className="col-md-6 py-5">
              <h2 className="text-left">WEBINARS</h2>
              <p className="text-left">
                Simplified webinar administration makes it easy to create engaging attendance experiences for audiences
                of any size.
              </p>
              <Link type="button" class="btn btn-primary" to="/home" className="home-link">
                Get Started
              </Link>
            </div>
            <div className="col-md-6 text-center">
              <div id="webinars-info">
                <img className="img-fluid" src="https://ujatcare.tel/assets/images/webinar.jpg" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="section-5">
        <div id="events-info">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 py-5 text-center">
                <img className="img-fluid" src="https://ujatcare.tel/assets/images/polling.jpg" />
              </div>
              <div className="col-md-6 text-center">
                <h2 className="text-center">POLLING</h2>
                <p className="text-center">
                  Organize physical, virtual, and hybrid events with the help of a robust platform that manages
                  registration, on-site solutions, networking, and everything in between..
                </p>
                <Link type="button" class="btn btn-primary" to="/home" className="home-link">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="homeFooter">
        <div id="container">
          <div className="row py-5 px-5">
            <div className="col-sm-12 col-md-3 col-lg-3">
              <img class="img-fluid" src="https://ujatcare.tel/assets/images/logo-ujat-care.png" />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <h3>About</h3>
              <p>
                UjatCare.tel presents the ultimate communication tool for individuals and businesses alike - our web app
                offers completely free calls, meetings, events, webinars, and polling.
              </p>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <h3>UjatCare.tel Help</h3>
              <ul>
                <li>Help Center</li>
                <li>UjatCare.tel Forums</li>
                <li>UjatCare.tel Kit</li>
                <li>Our site</li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <h3>Contact us</h3>
              <ul>
                <ul>
                  <li>Premium versions</li>
                  <li>Corporate</li>
                  <li>Mobile Version</li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
