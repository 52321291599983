/*
|--------------------------------------------------------------------------
| React App Documentation
|--------------------------------------------------------------------------
|
| This React app is the entry point of the application and serves as the main container for the entire UI.
| It uses Material-UI for styling and handles routing using 'react-router-dom'.
| The app provides different routes for various pages and components, such as login, registration, conference room, etc.
|
| Components:
|   - App: Main component responsible for rendering the application UI.
|   - VideoApp: Component that wraps the main App and handles video conferencing functionality.
|   - ReactApp: Main container that sets up the Material-UI theme and handles routing.
|
| Routes:
|   - /room/:URLRoomName?/:URLDescription?/:URLName? - Renders the VideoApp component for video conferencing. 
|   - / - Renders the Home component.
|   - /home - Renders the Home component.
|   - /hp - Renders the HomePage component.
|   - /login - Renders the Login component.
|   - /register - Renders the Register component.
|   - /confirm - Renders the AccountConfirm component.
|   - /reset - Renders the ResetPassword component.
|   - /profile - Renders the Profile component.
|   - /conference - Renders the VideoTwilio component.
|   - /join - Renders the Join component.
|   - /forgotPassword - Renders the ForgotPassword component.
|   - /resetPassword - Renders the ResetPassword component.
|   - /logout - Renders the Logout component.
|
| Dependencies:
|   - react
|   - react-dom
|   - react-router-dom
|   - @material-ui/core
|   - bootstrap
|   - twilio-video
|   - @twilio/video
|
*/

import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Routes, Link, HashRouter } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

// Import custom components
import App from './App';
import AppStateProvider, { useAppState } from './state';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { ParticipantProvider } from './components/ParticipantProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import AccountConfirm from './view/confirm';
import Dashboard from './view/Navbar';
import Home from './view/home';
import HomePage from './view/HomePage';
import Login from './view/login';
import Logout from './view/logout';
import Profile from './view/profile';
import Register from './view/register';
import Join from './Video.Connect';
import ForgotPassword from './view/ForgotPassword';
import ResetPassword from './view/ResetPassword';

// VideoApp component handles video conferencing functionality
const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ParticipantProvider>
        <ChatProvider>
          <App />
        </ChatProvider>
      </ParticipantProvider>
    </VideoProvider>
  );
};

// ReactApp component sets up the Material-UI theme and handles routing
export const ReactApp = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <HashRouter>
        <AppStateProvider>
          {/* Define route links */}
          <Link to="/room/:URLRoomName/:URLDescription/:URLName"></Link>
          <Link to="/room/:URLRoomName/:URLDescription"></Link>
          <Link to="/room/:URLRoomName"></Link>

          {/* Set up routes */}
          <Routes>
            <Route path="/room/:URLRoomName?/:URLDescription?/:URLName?" element={<VideoApp />} />
            <Route path="/room/:URLRoomName?/:URLDescription?" element={<VideoApp />} />
            <Route path="/room/:URLRoomName?" element={<VideoApp />} />
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="hp" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/confirm" element={<AccountConfirm />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/join" element={<Join />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </AppStateProvider>
      </HashRouter>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>
);

// Render the ReactApp component to the DOM
ReactDOM.render(<ReactApp />, document.getElementById('root'));
