import { useCallback, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { PhoneFill } from 'react-bootstrap-icons';
import { BACKEND_URL } from '../constants/config';
import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';

import Home from '../view/home';

function AddPhone(props) {
  const [show, setShow] = useState(false);
  const userId = props.idContactsGmail; // Acceder a la prop phone
  const [phoneNumber, setPhoneNumber] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSave = useCallback(async () => {
    if (phoneNumber === undefined) {
      toast.warn('Enter a phone number', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else if (!isPossiblePhoneNumber(phoneNumber) || !isValidPhoneNumber(phoneNumber)) {
      toast.warn('Enter a valid phone number', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else {
      try {
        await fetch(`${BACKEND_URL}/updateUserPhone`, {
          method: 'POST',
          body: JSON.stringify({
            accion: 'updateUserPhone',
            actionType: 'update',
            idContactsGmail: userId,
            phone: phoneNumber,
          }),
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
          .then(respose => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error('error');
          })
          .then(data => {
            toast.success(data.titulo, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
            setTimeout(() => {
              handleClose();
              window.location.reload();
            }, 200);
          });
      } catch (error) {
        console.log(error.message);
      }
    }
  }, [phoneNumber, userId]);

  return (
    <>
      <Button className="btn btn-primary btn-sm" variant="primary" onClick={handleShow}>
        <PhoneFill />
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Assign a phone number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p></p>
          <PhoneInput
            id="typeNumber"
            className="form-control"
            placeholder="Enter phone number"
            defaultCountry="US"
            international
            countryCallingCodeEditable={false}
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
          <small>You can additionally send a link to your invitation via SMS</small>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="dark">Send</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddPhone;
