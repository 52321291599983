import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { BACKEND_URL } from '../constants/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

/**
 * A functional component for resetting a user's password using a token.
 *
 * @returns {JSX.Element} JSX element containing the password reset form.
 */
function ResetPassword() {
  const { token } = useParams();

  // State variables to manage password input and visibility
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordsValid, setPasswordsValid] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordValid, setPasswordValid] = useState(false);

  // Retrieve codeReset from local storage
  const codeReset = localStorage.getItem('codeReset');
  const navigate = useNavigate();

  // Event handler for password change input
  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  // Toggle password visibility
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  // Event handler for confirming password input
  const confirmPasswordHandler = event => {
    setConfirmPassword(event.target.value);
    comparePasswords(password, event.target.value);
  };

  // Compare entered passwords for matching
  const comparePasswords = (password, confirmPassword) => {
    if (password === confirmPassword) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  // Event handler for password input
  const passwordHandler = event => {
    setPassword(event.target.value);
    validatePassword(event.target.value);
    if (passwordValid == true) {
      comparePasswords(event.target.value, confirmPassword);
    }
  };

  // Validate password length
  const validatePassword = password => {
    if (password.length >= 8) {
      setPasswordValid(true);
      setPasswordsValid(true);
      setPasswordsMatch(false);
    } else {
      setPasswordsValid(false);
      setPasswordValid(false);
      setPasswordsMatch(true);
    }
  };

  // Handle password change submission
  const handleSubmit = e => {
    e.preventDefault();
    if (password.length >= 8) {
      try {
        fetch(`${BACKEND_URL}/changePass`, {
          method: 'POST',
          body: JSON.stringify({
            accion: 'changePass',
            code: codeReset,
            password: password,
          }),
        })
          .then(respose => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error('error');
          })
          .then(data => {
            if (data.respuesta == 'success') {
              toast.success('password updated successfully');
              localStorage.setItem('codeReset', 'null');
              setTimeout(() => {
                navigate('/login');
              }, 1000);
            } else if (data.respuesta == 'email') {
              toast.warn(data.mensaje);
            } else {
              toast.warn(data.mensaje);
            }
          });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      toast.warn('Enter a valid password');
    }
  };

  // JSX rendering of password reset form based on codeReset value

  /*return (
        <div className="container mt-5">
            <ToastContainer />
            <div className="row justify-content-center">
                <div className="col-md-6 col-sm-8">
                    <div className="card bg-info">
                        <div className="card-body">
                            <h2 className="text-center text-white mb-4">Reset Password</h2>
                            <form>
                                <label htmlFor="password" className="text-white">New Password</label>
                                <input type={passwordShown ? 'text' : 'password'} className="form-control" value={password} onChange={passwordHandler} required />
                                <span className="badge text-bg-secondary" onClick={togglePasswordVisiblity}>
                                    {passwordShown ? 'Hide Pass' : 'Show Pass'}
                                </span>
                                {!passwordsValid && (
                                    <div class="alert alert-danger alert-sm" role="alert">
                                    please add a password with 8 characters
                                    </div>
                                )}
                                <div>

                                </div>
                                <label htmlFor="password" className="text-white">Confirm Password</label>
                                <input
                                    type={passwordShown ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={confirmPasswordHandler}
                                    className="form-control"
                                    required
                                />
                                {!passwordsMatch && (
                                    <div class="alert alert-danger" role="alert">
                                        Passwords do not match
                                    </div>
                                )}
                                {passwordsMatch && <button type="submit" className="btn btn-warning btn-block mt-3" onClick={handleSubmit}>Change</button>}
                            </form>
                            <Link to="/login" className="btn btn-light btn-block mt-2">Back to Login</Link>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );*/

  if (codeReset === 'null') {
    return (
      // JSX for form when codeReset is 'null'
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-8">
            <div className="card bg-info">
              <div className="card-body">
                <h2 className="text-center text-white mb-4">Reset Password</h2>
                <p>Please request a token, entering your email</p>
                <Link to="/forgotPassword" className="btn btn-warning btn-block mt-3">
                  Get token
                </Link>
                <Link to="/login" className="btn btn-light btn-block mt-2">
                  Back to Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      // JSX for form when codeReset is not 'null'
      <div className="container mt-5">
        <ToastContainer />
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-8">
            <div className="card bg-info">
              <div className="card-body">
                <h2 className="text-center text-white mb-4">Reset Password</h2>
                <form>
                  <label htmlFor="password" className="text-white">
                    New Password
                  </label>
                  <input
                    type={passwordShown ? 'text' : 'password'}
                    className="form-control"
                    value={password}
                    onChange={passwordHandler}
                    required
                  />
                  <span className="badge text-bg-secondary" onClick={togglePasswordVisiblity}>
                    {passwordShown ? 'Hide Pass' : 'Show Pass'}
                  </span>
                  {!passwordsValid && (
                    <div class="alert alert-danger alert-sm" role="alert">
                      please add a password with 8 characters
                    </div>
                  )}
                  <div></div>
                  <label htmlFor="password" className="text-white">
                    Confirm Password
                  </label>
                  <input
                    type={passwordShown ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={confirmPasswordHandler}
                    className="form-control"
                    required
                  />
                  {!passwordsMatch && (
                    <div class="alert alert-danger" role="alert">
                      Passwords do not match
                    </div>
                  )}
                  {passwordsMatch && (
                    <button type="submit" className="btn btn-warning btn-block mt-3" onClick={handleSubmit}>
                      Change
                    </button>
                  )}
                </form>
                <Link to="/login" className="btn btn-light btn-block mt-2">
                  Back to Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
