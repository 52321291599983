const cons = 'https://ujatcare.tel/consultar.php';
export const BACKEND_URL = 'https://ujatcare.tel/consultar.php';
//GoogleId
export const GOOGLE_USER_ID = '60729412509-s1e1sn3jbnqgfnvh0mbkvjue0thqee86.apps.googleusercontent.com';

//local
//const cons = 'http://localhost/ujat/ucc-video-component/consultar';
//export const GOOGLE_USER_ID = '1056845106668-85m5ijepstsp6afr51a0cdtvlcjaocu2.apps.googleusercontent.com';
//export const BACKEND_URL = 'http://localhost/ujat/ucc-video-component/consultar';

export default cons;
