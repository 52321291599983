/**
 * The ForgotPassword component allows users to initiate the password reset process by providing their email.
 * If a valid email is provided, a verification code is sent to the email address. Users can then enter the
 * received verification code to proceed with resetting their password.
 */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BACKEND_URL } from '../constants/config';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
  // State variables to manage user input and component behavior
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const navigate = useNavigate();

  // Event handler to validate and update the entered email
  const handleEmailChange = e => {
    const { value } = e.target;
    setEmail(value);
    setIsValidEmail(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value));
  };

  // Event handler to update the entered verification code
  const handleCodeChange = e => {
    setCode(e.target.value);
  };

  // Event handler to submit the email and request a verification code
  const handleSubmitEmail = e => {
    e.preventDefault();
    if (isValidEmail) {
      try {
        fetch(`${BACKEND_URL}/forgotPass`, {
          method: 'POST',
          body: JSON.stringify({
            accion: 'forgotPass',
            email: email,
          }),
        })
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            throw new Error('error');
          })
          .then(data => {
            if (data.respuesta === 'success') {
              localStorage.setItem('codeReset', data.data.token);
              toast.success('Token sent successfully');
              setIsCodeSent(true);
            } else {
              toast.warn(data.mensaje);
            }
          });
      } catch (error) {
        console.log(error.message);
      }
    } else {
      toast.warn('Please enter a valid email address');
    }
  };

  // Event handler to submit the verification code and navigate to reset password page
  const handleSubmitCode = e => {
    const codeReset = localStorage.getItem('codeReset');
    if (code === codeReset) {
      navigate('/resetPassword');
    } else {
      toast.warn('Please check the entered code');
    }
  };

  // UI rendering
  return (
    <div className="container mt-5">
      <ToastContainer />
      <div className="row justify-content-md-center">
        <div className="col"></div>
        <div className="col-md-auto">
          <h2 className="text-center mb-4">Forgot Password</h2>
          <form className="form-group">
            <label>Please enter your email</label>
            <label htmlFor="email" className="text-white">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="form-control"
              value={email}
              onChange={handleEmailChange}
              placeholder="email@email.com"
              required
            />
            {isCodeSent && (
              <div className="form-group mt-3">
                <label>Enter the verification code</label>
                <input type="text" value={code} onChange={handleCodeChange} className="form-control" required />
              </div>
            )}
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                type="submit"
                className="btn btn-warning mt-3"
                onClick={isCodeSent ? handleSubmitCode : handleSubmitEmail}
              >
                {isCodeSent ? 'Validate code' : 'Send code'}
              </button>
              <Link to="/" className="btn btn-info mt-2">
                Back to Login
              </Link>
            </div>
          </form>
        </div>
        <div className="col"></div>
      </div>
    </div>
  );
}

export default ForgotPassword;
