import React from 'react';
import { Link, useNavigate, Fragment } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { gapi } from 'gapi-script';
import './Home.css';
import GoogleContacts from 'react-google-contacts';
import { Modal, Button } from 'react-bootstrap';
import './Navbar.css';
import './logo-ujat-care.png';
import './homePage.css';
import { BACKEND_URL, GOOGLE_USER_ID } from '../constants/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-number-input';
import ComingSoon from '../components/ComingSoon';
import {
  ArrowRight,
  PersonAdd,
  ArrowClockwise,
  FileXFill,
  PersonCircle,
  PersonWorkspace,
  EnvelopeFill,
  EnvelopeCheckFill,
  PhoneFill,
  PlusCircleFill,
  BoxArrowInRight,
  PersonFillAdd,
} from 'react-bootstrap-icons';
import AddPhone from '../components/AddPhone';

export default function Home() {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const clientId = `${GOOGLE_USER_ID}`;
  const username = localStorage.getItem('username');
  const emailProfile = localStorage.getItem('email');
  const [contacts, setContacts] = useState([]);
  const arraySearch = contacts;
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [invited, setInvited] = useState([]);
  const [roomName, setRoomName] = useState([]);
  const [description, setDescription] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [disabled, setDisabled] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = contacts.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(contacts.length / itemsPerPage);
  const productsArray = [];
  const [phoneNumber, setPhoneNumber] = useState();
  const [nameAdd, setNameAdd] = useState('');
  const [emailAdd, setEmailAdd] = useState('');

  const [isShow, invokeModal] = React.useState(false);
  const initModal = () => {
    return invokeModal(!false);
  };
  const endModal = () => {
    return invokeModal(false);
  };
  const responseCallback = response => {
    response.forEach(productElement => {
      productsArray.push(productElement);
    });
    setContacts(productsArray);
  };

  const getUser = useCallback(async () => {
    try {
      await fetch(`${BACKEND_URL}/user`, {
        method: 'POST',
        body: JSON.stringify({
          accion: 'user',
        }),
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          setUser(data.status);
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getContacts = useCallback(async () => {
    try {
      await fetch(`${BACKEND_URL}/getContacts`, {
        method: 'POST',
        body: JSON.stringify({
          accion: 'getContacts',
          username: emailProfile,
        }),
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          const updatedData = data.map(obj => ({ ...obj, selected: false }));
          setContacts(updatedData);
          setDisabled(updatedData);
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getContactsGmail = useCallback(async () => {
    try {
      await fetch(`${BACKEND_URL}/getContactsGmail`, {
        method: 'POST',
        body: JSON.stringify({
          accion: 'getContactsGmail',
          username: emailProfile,
        }),
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          const updatedData = data.map(obj => ({ ...obj, selected: false }));
          setContacts(updatedData);
          setDisabled(updatedData);
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(
    () => {
      getUser();
      getContactsGmail();
      const initClient = () => {
        gapi.client.init({
          clientId: clientId,
          scope: 'email profile',
        });
      };
      gapi.load('client:auth2', initClient);
    },
    [getUser],
    [getContactsGmail]
  );

  async function saveContact() {
    try {
      await fetch(`${BACKEND_URL}/contact`, {
        method: 'POST',
        body: JSON.stringify({
          accion: 'contact',
          data: contacts,
          username: emailProfile,
        }),
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          if (data.respuesta == 'success') {
            toast.success('Contacts saved successfully');
          } else {
            toast.error('Something wrong please check');
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  async function saveContactIndividual() {
    if (nameAdd.length < 7) {
      toast.warn('Please enter your full name');
    } else if (emailAdd.length < 5) {
      toast.warn('Please enter your email');
    } else if (phoneNumber.length < 7) {
      console.log(phoneNumber);
      toast.warn('Please enter your phone number');
    } else {
      const data = {
        nameAdd: nameAdd,
        emailAdd: emailAdd,
        username: emailProfile,
        phoneNumber: phoneNumber,
      };
      try {
        await fetch(`${BACKEND_URL}/contact`, {
          method: 'POST',
          body: JSON.stringify({
            accion: 'addNewContact',
            data: data,
          }),
        })
          .then(respose => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error('error');
          })
          .then(data => {
            if (data.respuesta == 'success') {
              endModal();
              getContacts();
              toast.success('Contacts saved successfully');
              setNameAdd('');
              setEmailAdd('');
              setPhoneNumber('');
            } else {
              toast.error('Something wrong please check');
            }
          });
      } catch (error) {
        console.log(error.message);
      }
    }
  }

  async function handleCall(contact) {
    setEmail(contact.email);
    setName(contact.title);
    startCall();
  }

  async function sendInvitation(contact) {
    setInvited(prevInvited => [...prevInvited, { ...contact, selected: true }]);
  }

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  async function startCall() {
    try {
      await fetch(`${BACKEND_URL}/call`, {
        method: 'POST',
        body: JSON.stringify({
          accion: 'call',
          data: email,
          name: name,
          username: username,
        }),
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          if (data.respuesta == 'success') {
            toast.success('Invitation sent');
            navigate(data.data + '/' + username);
          } else {
            toast.error('Sometihng wrong please try again');
          }
        });
    } catch (error) {
      toast.error('Sometihng wrong please try again');
    }
  }

  const handleUpdateContacts = () => {
    setContacts([]);
  };

  const startMeting = () => {
    if (roomName.length < 7) {
      toast.warn('Please enter room name');
    } else if (description.length < 5) {
      toast.warn('Please enter description');
    } else {
      const selectedContacts = contacts.filter(contact => contact.selected);
      if (selectedContacts.length >= 1) {
        callMultiple(selectedContacts);
      } else {
        toast.warn('please select contacts', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }
  };

  async function callMultiple(selectedContacts) {
    try {
      await fetch(`${BACKEND_URL}/callMultiple`, {
        method: 'POST',
        body: JSON.stringify({
          accion: 'callMultiple',
          user: username,
          roomName: roomName,
          description: description,
          data: selectedContacts,
        }),
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          if (data.respuesta == 'success') {
            toast.error('Invitation sent');
            navigate(data.data + '/UjatCares/' + username);
          } else {
            gapi.auth2
              .getAuthInstance()
              .signOut()
              .then(() => {
                toast.warn('Sometihng wrong please check');
              });
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }

  const startWebinar = () => {
    if (roomName.length < 7) {
      toast.warn('Please enter room name');
    } else if (description.length < 5) {
      toast.warn('Please enter description');
    } else {
      const selectedContacts = contacts.filter(contact => contact.selected);
      if (selectedContacts.length >= 1) {
        callWebinar(selectedContacts);
      } else {
        toast.warn('please select contacts', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }
  };

  async function callWebinar(selectedContacts) {
    try {
      await fetch(`${BACKEND_URL}/callWebinar`, {
        method: 'POST',
        body: JSON.stringify({
          accion: 'callWebinar',
          user: username,
          roomName: roomName,
          description: description,
          data: selectedContacts,
        }),
      })
        .then(respose => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error('error');
        })
        .then(data => {
          if (data.respuesta == 'success') {
            toast.error('Invitation sent');
            const roomLink = `https://ujatwebinar-6ssc20r8y-ertzoc.vercel.app/?roomID=${data.data}&name=${username}`;
            window.location.href = roomLink;
          } else {
            gapi.auth2
              .getAuthInstance()
              .signOut()
              .then(() => {
                toast.warn('Sometihng wrong please check');
              });
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }
  const saveOne = () => {
    console.log('save contact');
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid px-5 py-2">
          <div id="logo-ujat">
            <a className="navbar-brand" href="#">
              <img className="img-fluid" src="https://ujatcares.com/assets/ujatCare_logo.png" />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              {localStorage.getItem('token') ? (
                <>
                  <div>
                    <ToastContainer />
                  </div>
                  <li className="nav-item">
                    <Link to="/profile" className="nav-link">
                      Profile <PersonCircle />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/logout" className="nav-link">
                      Log Out <ArrowRight />
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link to="/register" className="nav-link">
                      Register {''} <PersonFillAdd />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/login" className="nav-link">
                      Login {''} <BoxArrowInRight />
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <div className="container-fluid" id="home-container">
        {localStorage.getItem('token') ? (
          <>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="true"
                  onClick={getContactsGmail}
                >
                  Contact from Google
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="contact-tab-pane"
                  aria-selected="false"
                  onClick={getContacts}
                >
                  Register Contact
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <ComingSoon />
              {/* <div
                className="tab-pane fade show active"
                id="profile-tab-pane"
                role="tabpanel"
                aria-labelledby="profile-tab"
                tabIndex="0"
              >
                {disabled.length > 1 ? (
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
                    <button type="button" className="btn btn-success me-md-2" onClick={handleUpdateContacts}>
                      Update Contacts <ArrowClockwise />
                    </button>
                    <button onClick={saveContact} type="button" className="btn btn-success me-md-2">
                      Save Contacts <PersonAdd />
                    </button>
                  </div>
                ) : (
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
                    <button onClick={saveContact} type="button" className="btn btn-success me-md-2">
                      Save Contacts <PersonAdd />
                    </button>
                  </div>
                )}
                <div>
                  {contacts.length < 1 ? (
                    <>
                    
                      <GoogleContacts
                        clientId={`${GOOGLE_USER_ID}`}
                        scope={
                          'https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/contacts.other.readonly'
                        }
                        onSuccess={responseCallback}
                        onFailure={responseCallback}
                      >
                        <span> Import contacts from Gmail</span>
                      </GoogleContacts>

                    </>
                  ) : (
                    <>
                      <div>
                        <input
                          type="text"
                          value={searchTerm}
                          onChange={e => setSearchTerm(e.target.value)}
                          placeholder="Search"
                          style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px', width: '200px' }}
                        />
                        <div className="row">
                          <div className="col-md-8">
                            <table
                              style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ccc' }}
                              className="contact-table mt-2"
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      backgroundColor: '#f0f0f0',
                                      padding: '10px',
                                      borderBottom: '1px solid #ccc',
                                    }}
                                  >
                                    No
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: '#f0f0f0',
                                      padding: '10px',
                                      borderBottom: '1px solid #ccc',
                                    }}
                                  >
                                    Name
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: '#f0f0f0',
                                      padding: '10px',
                                      borderBottom: '1px solid #ccc',
                                    }}
                                  >
                                    Email
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: '#f0f0f0',
                                      padding: '10px',
                                      borderBottom: '1px solid #ccc',
                                    }}
                                  >
                                    Phone
                                  </th>
                                  <th
                                    style={{
                                      backgroundColor: '#f0f0f0',
                                      padding: '10px',
                                      borderBottom: '1px solid #ccc',
                                    }}
                                  >
                                    Option
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {currentItems
                                  .filter(
                                    arraySearch =>
                                      (arraySearch.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
                                        arraySearch.email &&
                                        arraySearch.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                      (arraySearch.gmail &&
                                        arraySearch.gmail.toLowerCase().includes(searchTerm.toLowerCase()))
                                  )
                                  .map((contact, index) => {
                                    if (contact.isGmail) {
                                      return (
                                        <tr key={index}>
                                          <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                                            {index + 1}
                                          </td>
                                          <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                                            {contact.title}
                                          </td>
                                          <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                                            {contact.email ? contact.email : contact.gmail}
                                          </td>
                                          {contact.phone != 0 ? (
                                            <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                                              {contact.phone}
                                            </td>
                                          ) : (
                                            <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                                              <AddPhone idContactsGmail={contact.idContactsGmail} />
                                            </td>
                                          )}
                                          <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                                            <div
                                              className="btn-group"
                                              role="group"
                                              aria-label="Basic mixed styles example"
                                            >
                                              <button
                                                type="button"
                                                className={`btn btn-sm ${
                                                  contact.selected ? 'btn-success' : 'btn-secondary'
                                                }`}
                                                title="Select for inviting meeting group"
                                                onClick={() => {
                                                  contact.selected = !contact.selected;
                                                  sendInvitation(contact);
                                                }}
                                              >
                                                {contact.selected ? (
                                                  <>
                                                    Unselected <EnvelopeCheckFill /> {contact.selected}
                                                  </>
                                                ) : (
                                                  <>
                                                    Select <EnvelopeFill /> {contact.selected}
                                                  </>
                                                )}
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}
                              </tbody>
                            </table>
                            <div className="mt-3">
                              <button
                                className="btn btn-secondary"
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(currentPage - 1)}
                              >
                                Previous
                              </button>
                              {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                  className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-secondary'} mx-1`}
                                  key={index}
                                  onClick={() => handlePageChange(index + 1)}
                                >
                                  {index + 1}
                                </button>
                              ))}
                              <button
                                className="btn btn-secondary"
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="card">
                              <div className="card-body">
                                <div className="table-responsive-sm">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {contacts.map((contact, index) => {
                                        if (contact.selected) {
                                          return (
                                            <tr key={index}>
                                              <td scope="row">{contact.title}</td>
                                              <td>
                                                <span
                                                  className="badge bg-danger"
                                                  onClick={() => {
                                                    contact.selected = !contact.selected;
                                                    sendInvitation(contact);
                                                  }}
                                                >
                                                  Cancel
                                                </span>
                                              </td>
                                            </tr>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                                <div>
                                  <div className="mb-3">
                                    <label htmlFor="roomName" className="form-label">
                                      Room Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="roomName"
                                      placeholder="Team Developers"
                                      onChange={e => setRoomName(e.target.value)}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label htmlFor="description" className="form-label">
                                      Description Meeting
                                    </label>
                                    <textarea
                                      className="form-control"
                                      id="description"
                                      rows="3"
                                      onChange={e => setDescription(e.target.value)}
                                    ></textarea>
                                  </div>
                                  <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                    <button
                                      type="button"
                                      className="btn btn-success me-md-2 mt-2 "
                                      onClick={startMeting}
                                    >
                                      Start Meeting <PersonWorkspace />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div> */}
              <div
                className="tab-pane fade"
                id="contact-tab-pane"
                role="tabpanel"
                aria-labelledby="contact-tab"
                tabIndex="0"
              >
                <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
                  <button
                    className="btn btn-success me-md-2"
                    type="button"
                    onClick={initModal}
                    title="Register new contact"
                  >
                    Add contact <PlusCircleFill />
                  </button>
                  <Modal show={isShow}>
                    <Modal.Header closeButton onClick={endModal}>
                      <Modal.Title>Add new contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="mb-3">
                        <label className="form-label">Name</label>
                        <input
                          type="Text"
                          className="form-control mb-3"
                          name="name"
                          id="name"
                          placeholder="John Doe"
                          value={nameAdd}
                          onChange={e => setNameAdd(e.target.value)}
                        />
                        <label className="form-label">Email</label>
                        <input
                          type="email"
                          className="form-control mb-3"
                          name="email"
                          id="email"
                          aria-describedby="emailHelpId"
                          placeholder="example@domain.com"
                          value={emailAdd}
                          onChange={e => setEmailAdd(e.target.value)}
                        />
                        <label className="form-label">Pnone</label>
                        <PhoneInput
                          id="typeNumber"
                          className="form-control"
                          placeholder="Enter your phone number"
                          defaultCountry="US"
                          international
                          countryCallingCodeEditable={false}
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button type="button" className="btn btn-danger" onClick={endModal}>
                        Cancel
                      </button>
                      <button type="button" className="btn btn-success" onClick={saveContactIndividual}>
                        Save
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <>
                  <div>
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                      placeholder="Search"
                      style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '5px', width: '200px' }}
                    />
                    <div className="row">
                      <div className="col-md-8">
                        <table
                          style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ccc' }}
                          className="contact-table mt-2"
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  backgroundColor: '#f0f0f0',
                                  padding: '10px',
                                  borderBottom: '1px solid #ccc',
                                }}
                              >
                                No
                              </th>
                              <th
                                style={{
                                  backgroundColor: '#f0f0f0',
                                  padding: '10px',
                                  borderBottom: '1px solid #ccc',
                                }}
                              >
                                Name
                              </th>
                              <th
                                style={{
                                  backgroundColor: '#f0f0f0',
                                  padding: '10px',
                                  borderBottom: '1px solid #ccc',
                                }}
                              >
                                Email
                              </th>
                              <th
                                style={{
                                  backgroundColor: '#f0f0f0',
                                  padding: '10px',
                                  borderBottom: '1px solid #ccc',
                                }}
                              >
                                Phone
                              </th>
                              <th
                                style={{
                                  backgroundColor: '#f0f0f0',
                                  padding: '10px',
                                  borderBottom: '1px solid #ccc',
                                }}
                              >
                                Option
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems
                              .filter(
                                arraySearch =>
                                  (arraySearch.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
                                    arraySearch.email &&
                                    arraySearch.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                  (arraySearch.gmail &&
                                    arraySearch.gmail.toLowerCase().includes(searchTerm.toLowerCase()))
                              )
                              .filter(contact => contact.isGmail == 0)
                              .map((contact, index) => {
                                if (contact.isGmail) {
                                  return (
                                    <tr key={index}>
                                      <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>{index + 1}</td>
                                      <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                                        {contact.title}
                                      </td>
                                      <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                                        {contact.email ? contact.email : contact.gmail}
                                      </td>
                                      {contact.phone != 0 ? (
                                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                                          {contact.phone}
                                        </td>
                                      ) : (
                                        <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                                          <AddPhone idContactsGmail={contact.idContactsGmail} />
                                        </td>
                                      )}
                                      <td style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                                        <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                          <button
                                            type="button"
                                            className={`btn btn-sm ${
                                              contact.selected ? 'btn-success' : 'btn-secondary'
                                            }`}
                                            title="Select for inviting meeting group"
                                            onClick={() => {
                                              contact.selected = !contact.selected;
                                              sendInvitation(contact);
                                            }}
                                          >
                                            {contact.selected ? (
                                              <>
                                                Unselected <EnvelopeCheckFill /> {contact.selected}
                                              </>
                                            ) : (
                                              <>
                                                Select <EnvelopeFill /> {contact.selected}
                                              </>
                                            )}
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                } else {
                                  return null; // Omitir los contactos que no tengan isGmail en true
                                }
                              })}
                          </tbody>
                        </table>
                        <div className="mt-3">
                          <button
                            className="btn btn-secondary"
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            Previous
                          </button>
                          {Array.from({ length: totalPages }, (_, index) => (
                            <button
                              className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-secondary'} mx-1`}
                              key={index}
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </button>
                          ))}
                          <button
                            className="btn btn-secondary"
                            disabled={currentPage === totalPages}
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body">
                            <div className="table-responsive-sm">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {contacts.map((contact, index) => {
                                    if (contact.selected) {
                                      return (
                                        <tr key={index}>
                                          <td scope="row">{contact.title}</td>
                                          <td>
                                            <span
                                              className="badge bg-danger"
                                              onClick={() => {
                                                contact.selected = !contact.selected;
                                                sendInvitation(contact);
                                              }}
                                            >
                                              Cancel
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })}
                                </tbody>
                              </table>
                            </div>
                            <div>
                              <div className="mb-3">
                                <label htmlFor="roomName" className="form-label">
                                  Room Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="roomName"
                                  placeholder="Team Developers"
                                  onChange={e => setRoomName(e.target.value)}
                                />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="description" className="form-label">
                                  Description Meeting
                                </label>
                                <textarea
                                  className="form-control"
                                  id="description"
                                  rows="3"
                                  onChange={e => setDescription(e.target.value)}
                                ></textarea>
                              </div>
                              <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                                <button type="button" className="btn btn-success me-md-2 mt-2 " onClick={startMeting}>
                                  Start Meeting <PersonWorkspace />
                                </button>
                                <button type="button" className="btn btn-success me-md-2 mt-2 " onClick={startWebinar}>
                                  Generate Webinar <PersonWorkspace />
                                </button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="hp">
              <section id="section-1">
                <div className="container">
                  <div className="row" id="intro">
                    <div className="col-md-6">
                      <h1 className="main-intro">On the go Video Communications Services</h1>
                      <p>
                        No personal data tracking or selling to 3rd. Parties No software to download Free.... yes it is
                        FREE
                      </p>
                      <Link type="button" className="btn btn-primary home-link" to="/login">
                        Login
                      </Link>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </div>
              </section>

              <section id="section-2">
                <div id="meetings-info">
                  <div className="container">
                    <div className="row align-items-centerj py-5">
                      <div className="col-md-12 text-center">
                        <h2 className="text-center">MEETINGS</h2>
                        <p className="text-center">
                          No matter where they are, what language they speak, or how they choose to communicate, create
                          an engaging meeting environment that unites everyone.
                        </p>
                        <Link type="button" className="btn btn-primary home-link" to="/login">
                          Login
                        </Link>
                      </div>
                      <div className="col-md-6 offset-md-3 py-5">
                        <img className="img-fluid" src="https://i.ibb.co/Gc8VRwN/vid-conf.jpg" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section id="section-3">
                <div id="events-info">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <img className="img-fluid" src="https://ujatcare.tel/assets/images/sq4.png" />
                      </div>
                      <div className="col-md-6 text-center">
                        <h2 className="text-center">EVENTS</h2>
                        <p className="text-center">
                          Organize physical, virtual, and hybrid events with the help of a robust platform that manages
                          registration, on-site solutions, networking, and everything in between..
                        </p>
                        <Link type="button" className="btn btn-primary home-link" to="/login">
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section id="section-4">
                <div className="container">
                  <div className="row align-items-center py-5">
                    <div className="col-md-6 py-5">
                      <h2 className="text-left">WEBINARS</h2>
                      <p className="text-left">
                        Simplified webinar administration makes it easy to create engaging attendance experiences for
                        audiences of any size.
                      </p>
                      <Link type="button" className="btn btn-primary home-link" to="/login">
                        Login
                      </Link>
                    </div>
                    <div className="col-md-6 text-center">
                      <div id="webinars-info">
                        <img className="img-fluid" src="https://ujatcare.tel/assets/images/webinar.jpg" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section id="section-5">
                <div id="events-info">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-6 py-5 text-center">
                        <img className="img-fluid" src="https://ujatcare.tel/assets/images/polling.jpg" />
                      </div>
                      <div className="col-md-6 text-center">
                        <h2 className="text-center">POLLING</h2>
                        <p className="text-center">
                          Organize physical, virtual, and hybrid events with the help of a robust platform that manages
                          registration, on-site solutions, networking, and everything in between..
                        </p>
                        <Link type="button" className="btn btn-primary home-link" to="/login">
                          Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section id="homeFooter">
                <div id="container">
                  <div className="row py-5 px-5">
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <img className="img-fluid" src="https://ujatcare.tel/assets/images/logo-ujat-care.png" />
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <h3>About</h3>
                      <p>
                        UjatCare.tel presents the ultimate communication tool for individuals and businesses alike - our
                        web app offers completely free calls, meetings, events, webinars, and polling.
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <h3>UjatCare.tel Help</h3>
                      <ul>
                        <li>Help Center</li>
                        <li>UjatCare.tel Forums</li>
                        <li>UjatCare.tel Kit</li>
                        <li>Our site</li>
                      </ul>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <h3>Contact us</h3>
                      <ul>
                        <ul>
                          <li>Premium versions</li>
                          <li>Corporate</li>
                          <li>Mobile Version</li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
