import React, { useState } from 'react';

function JoinRoomForm({ onJoin }) {
  const [roomName, setRoomName] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    onJoin(roomName);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Room name:
        <input type="text" value={roomName} onChange={event => setRoomName(event.target.value)} />
      </label>
      <button type="submit">Join room</button>
    </form>
  );
}

export default JoinRoomForm;
