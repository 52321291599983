import React from 'react';
import './Navbar.css';
import './logo-ujat-care.png';

const Navbar = () => {
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid px-5 py-2">
        <div id="logo-ujat">
          <a class="navbar-brand" href="#">
            <img className="img-fluid" src="https://ujatcare.tel/assets/images/logo-ujat-care.png" />
          </a>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/home">
                Meetings
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/home">
                Events
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/home">
                Webinars
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link disabled" href="/home" tabindex="-1" aria-disabled="true">
                Polling
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
